.coverphotocontainer {
  position: relative;
}

.coverphoto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(64px,1fr) minmax(64px,1fr)) minmax(64px,1fr);
  grid-template-rows: repeat(4, 64px);
  grid-auto-rows: 0px;
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.spritecontainer {
  text-align: center;
  display: flex;
  justify-content: center;
}

.spritecontainer:nth-child(2n) {
  background-color: #faffff;
}

.spritecontainer:nth-child(2n+1) {
  background-color: #f3f3f3;
}

.spriteimage {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  image-rendering: -webkit-optimize-contrast;
  /*image-rendering: optimizeSpeed;*/
  align-self: center;
  max-height: 100%;
}

@media (min-width: 768px) {
  .coverphoto {
    grid-template-columns: repeat(auto-fill, minmax(96px,1fr) minmax(96px,1fr)) minmax(96px,1fr);
    grid-template-rows: repeat(2, 96px);
  }
}

.gifoptions {
  position: absolute;
  right: 10px;
  font-size: 12px;
}
