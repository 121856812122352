.titlecontainer {
  padding: 20px;
}

.title {
  font-size: 22px;
  line-height: 1.5;
}

.motto {
  font-style: italic;
  font-size: 16px;
  line-height: 1.5;
}

.centeredtext {
  text-align: center;
}

@media (min-width: 768px) {
  .titlecontainer {
    padding: 30px;
  }  
  .title {
    font-size: 28px;
    line-height: 2;
  }
  .motto {
    font-size: 20px;
    line-height: 2;
  }
}
