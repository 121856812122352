.section {
  padding: 20px 0;
}

.section:nth-of-type(2n+1) {
  background-color: #f0f1f3;
}

.content {
  margin: 0 10px;
}

.sprite {
  display: flex;
  height: 120px;
}

.spritelarge {
  height: 150px;
}

.spritetext {
  align-self: flex-start;
  width: 96px;
}

.spriteimage {
  align-self: flex-end;
  width: 96px;
}

.rightsprite {
  display: flex;
  height: 150px;
  justify-content: flex-end;
  margin-right: -10px; /* cancels out margin from .content */
}

.rightspritetext {
  align-self: flex-end;
  width: 96px;
}

.rightspriteimage {
  align-self: center;
  width: 256px;
}

.mailinglistiframe {
  width: 100%;
  height: 600px;
}


.maintext {
  font-size: 18px;
  line-height: 2;
}

.smalltext {
  font-size: 12px;
}

.textblock {
  margin-bottom: 20px;
}

@media (max-width: 375px) {
  .rightsprite {
    flex-direction: column-reverse;
  }
  .rightspritetext {
    align-self: flex-start;
    margin-top: -20px;
  }
  .rightspriteimage {
    align-self: flex-end;
  }
}

@media (min-width: 769px) {
  /* max-width for desktop */
  .content {
    max-width: 768px;
  }
}

@media (min-width: 768px) {
  .section {
    padding: 40px 0;
  }
  /* max-width for iPad mini */
  .content {
    max-width: 748px;
    margin: auto;
  }
  .contentcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mailinglistiframe {
    width: 540px;
    height: 490px;
  }
  .sprite {
    height: 270px;
  }
  .spritelarge {
    height: 270px;
  }
  .spritetext {
    align-self: flex-start;
    width: 192px;
  }
  .spriteimage {
    align-self: flex-end;
    width: 192px;
  }
  .lefttext {
    max-width: 200px;
  }
  .rightspritecontainer {
    position: absolute;
    right: -400px;
  }
  .rightsprite {
    height: auto;
    justify-content: flex-start;
    margin-right: 0px;
  }
  .rightspritetext {
    width: 192px;
  }
  .rightspriteimage {
    width: 768px;
    align-self: flex-start;
  }
}

@media (min-width: 992px) {
  .rightspritecontainer {
    right: -270px;
  }
  .lefttext {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .rightspritecontainer {
    right: -210px;
  }
  .lefttext {
    max-width: 300px;
  }
}

@media (min-width: 1622px) {
  .rightspritecontainer {
    right: 0px;
  }
  .lefttext {
    max-width: 300px;
  }
}
