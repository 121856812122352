.patreoncontainer {
  width: 11.5rem;
}

.patreonbutton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.46875rem 1rem;
  pointer-events: unset;
  text-align: center;
  text-transform: none;
  background-color: rgb(255, 66, 77);
  border: 1px solid rgb(255, 66, 77);
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 9999px;
  backface-visibility: hidden;
  color: white;
  text-decoration: none;
  width: 100%;
  height: unset;
}

.patreonbuttoncontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spacer {
  width: 0.5rem;
}